import Web3 from 'web3';
//smart contract ABI
import MerkleDistributor from './merkle-distributor/MerkleDistributor.json'
//merkle tree generated by merkle distributor
import tree from './merkleTree.json'
const MERKLE_DISTRIBUTOR_ADDRESS = "0x0BADCFc9C2522deA14d0acBCBEd0111C819A1823"
export default class MerkleDistributorClient {
    constructor(){
        window.web3 = new Web3(window.ethereum);
        let abtract = MerkleDistributor.abi
        this.distributorInstance = new window.web3.eth.Contract(abtract, MERKLE_DISTRIBUTOR_ADDRESS)
        this.tree = tree
    }
    
    async claimToken(userAddress){
        
        const claimAccounts = Object.keys(tree.claims).map(e => e.toLowerCase())
        const claimAccountsArr = Object.keys(tree.claims).map(ele => {
            return {
                address: ele.toLowerCase(),
                index: tree.claims[ele]['index'],
                amount: tree.claims[ele]['amount'],
                proof: tree.claims[ele]['proof']
            }
        })
        if (claimAccounts.includes(userAddress)) {
            const proofOfAddress = claimAccountsArr[claimAccounts.indexOf(userAddress)].proof
            const indexOfAddress = claimAccountsArr[claimAccounts.indexOf(userAddress)].index
            const amountOfAddress = claimAccountsArr[claimAccounts.indexOf(userAddress)].amount 
            let txHash = await this.distributorInstance.methods.claim(indexOfAddress, userAddress, amountOfAddress, proofOfAddress)
            .send({from: userAddress}, function(error, transactionHash){
                if(error){
                    console.log(error)
                    return false
                }
                return transactionHash.hash
            })
            return txHash;
        }
        else{
            return false
        }
    }

    async isClaimed(userAddress){
        const claimAccounts = Object.keys(tree.claims).map(e => e.toLowerCase())
        const claimAccountsArr = Object.keys(tree.claims).map(ele => {
            return {
                address: ele.toLowerCase(),
                index: tree.claims[ele]['index'],
                amount: tree.claims[ele]['amount'],
                proof: tree.claims[ele]['proof']
            }
        })
        if (claimAccounts.includes(userAddress)) {
            const indexOfAddress = claimAccountsArr[claimAccounts.indexOf(userAddress)].index
            let txRes = await this.distributorInstance.methods.isClaimed(indexOfAddress).call()
            return txRes
        }else{
            return false

            
        }
    }
    
}