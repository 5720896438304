import React from 'react'
import { MDBFooter,MDBContainer } from 'mdb-react-ui-kit';
import {FaTwitter, FaFacebook, FaInstagram, FaDiscord} from "react-icons/fa";
import Grid from "@material-ui/core/Grid";


export default function Footer() {
    return (
        <Grid container justify="center"> 
        <div className="footer" >
                 <MDBFooter  className='bg-dark text-center text-black text-black outline-black form-black icon-black'>
                    <MDBContainer className='p-4 pb-0'>
                        <section className='mb-4'>
                            <a target = {"_blank"} className='btn btn-outline-light btn-floating m-1' href='https://discord.gg/Tax5Hkt9J6' role='button'>
                                <FaDiscord fab icon='facebook-f' />
                            </a>

                            <a target = {"_blank"}  className='btn btn-outline-light btn-floating m-1' href='https://instagram.com/plur_coin?utm_medium=copy_link' role='button'>
                                <FaInstagram fab icon='twitter' />
                            </a>

                            <a  target = {"_blank"} className='btn btn-outline-light btn-floating m-1' href='https://twitter.com/plurcoin_?s=11' role='button'>
                                <FaTwitter fab icon='google' />
                            </a>
                            <div className="copy" style={{color:"white",fontSize: 15 }}> © 2022 Copyright PLURcoin.</div>
                        </section>
                    </MDBContainer>
                    
                </MDBFooter>
        </div>
        </Grid>
    )
}
