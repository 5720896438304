import React, { useState } from 'react';
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBIcon,
  MDBCollapse
} from 'mdb-react-ui-kit';


import "./footer.css"


import "./toggler.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import HAM from "./ham4.png"
import ICON from "./Icon.png"

import { useNavigate} from "react-router-dom"


export default function Header() {
  const [showNav, setShowNav] = useState(false);




  return (
    <MDBNavbar expand='lg' dark href='#' style={{
      backgroundColor: "#343a40",
      color: "white",
      padding: "20px"
    }}>
      <MDBContainer fluid>
        <MDBNavbarBrand className='navbarToggler'style={{color: "white", fontFamily: "tank", fontSize: "12px"}} href='#'>           <img
              src={ICON}
              height='40'
              color='white'
              alt=''
              loading='lazy'
            /></MDBNavbarBrand>
        <MDBNavbarToggler className="icon" 
          
          type='button'
          aria-expanded='tue'
          aria-label='Toggle navigation'
          onClick={() => setShowNav(!showNav)}
        >
          <img src={HAM} height='25'
              color='white'
              alt=''
              loading='lazy' style={{color: "white"}} icon='bars' fas />
        </MDBNavbarToggler>
        <MDBCollapse style={{color: "white"}} navbar show={showNav}>
          <MDBNavbarNav>
            <MDBNavbarItem>
              <MDBNavbarLink className='navLinks' style={{ fontFamily: "pump", color: "white", fontSize: "15px"}}                
                   active aria-current='page' href='https://www.plurcoin.com/'
                  >
                HOME
              </MDBNavbarLink>
            </MDBNavbarItem>
            
           
            <MDBNavbarItem>
              <MDBNavbarLink disabled href='#' tabIndex={-1} aria-disabled='true'>
                
              </MDBNavbarLink>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  );
}